import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useAgencyAdmin } from "./useAgencyAdmin";

interface MethodsOfPaymentAdminInput {
}

export function useMethodsOfPaymentAdmin(props:MethodsOfPaymentAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedAgency, callAgencyAdmin } = useAgencyAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const testPayLaterForm:Ref<any> = ref(null);
  const productionPayLaterConfig:Ref<any> = ref(null);  

  const isMethodsCreateAllowed:Ref<boolean> = ref(false);
  const isMethodsPublishAllowed:Ref<boolean> = ref(false);

  const updateMethodsOfPayment = async () => {

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/payment');
      // We check the additional fields
      if(response.payLater) {
        if(response.payLater.staging) {
          testPayLaterForm.value = response.payLater.staging;
        }
        if(response.payLater.production) {
          productionPayLaterConfig.value = {
            ...response.payLater.production,
          }
        }
      }
      if(response.options) {
        isMethodsCreateAllowed.value = response.options.isCreateAllowed;
        isMethodsPublishAllowed.value = response.options.isPublishAllowed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateMethodsOfPayment();

  const createPayLater = async () => {

    var input = {
      ...testPayLaterForm.value
    }

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/payment/pay-later/create', input);
      // We check the additional fields
      if(response.payLater) {
        testPayLaterForm.value = response.payLater;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const updatePayLater = async () => {

    var input = {
      ...testPayLaterForm.value
    }

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/payment/pay-later/update', input);
      // We check the additional fields
      if(response.payLater) {
        testPayLaterForm.value = response.payLater;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const publishPayLater = async () => {

    isListLoading.value = true;

    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/payment/pay-later/publish');
      // We check the additional fields
      if(response.payLater) {
        productionPayLaterConfig.value = {
          ...response.payLater
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  // We check when there is a new shop selected
  watch(
    () => selectedAgency.value.agency._id,
    (val:any, oldVal:any) => {
      // We update the methodsOfPayment when the selected shop is updated
      updateMethodsOfPayment();
    },
    { deep: false }
  )

  return {
    isListLoading,
    createPayLater,
    updatePayLater,
    publishPayLater,
    testPayLaterForm,
    productionPayLaterConfig,
    isMethodsCreateAllowed,
    isMethodsPublishAllowed
  }
  
}