<template>
  <div class="ibox methodsOfPayment">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.title) }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <h3>Staging</h3>
      <div class="row">
        <div class="col-lg-6">
          <TextField 
            ref="updateBookingNotificationEMail"
            v-bind:value.sync="agencyConfigurationForm.bookingNotificationEMail" 
            id="updateBookingNotificationEMail" 
            :label="getLocalizedText(labels.bookingNotificationEMail)" 
            :placeholder="getLocalizedText(labels.bookingNotificationEMail_placeholder)" 
            :labelAsColumn="true"
            :required="true"
          />
          <LocalizedText 
            v-bind:value.sync="agencyConfigurationForm.bookingValidationNotificationDefaultMessage" 
            id="bookingValidationNotificationDefaultMessage" 
            :label="getLocalizedText(labels.bookingValidationNotificationDefaultMessage)" 
            :placeholder="getLocalizedText(labels.bookingValidationNotificationDefaultMessage_placeholder)" 
            :languageCodes="availableLanguageCodes" 
            labelAsColumn 
          />
          <LocalizedText 
            v-bind:value.sync="agencyConfigurationForm.bookingCancelationNotificationDefaultReason" 
            id="bookingCancelationNotificationDefaultReason" 
            :label="getLocalizedText(labels.bookingCancelationNotificationDefaultReason)" 
            :placeholder="getLocalizedText(labels.bookingCancelationNotificationDefaultReason_placeholder)" 
            :languageCodes="availableLanguageCodes" 
            labelAsColumn 
          />
          <LocalizedText 
            v-bind:value.sync="agencyConfigurationForm.bookingBeforeCheckoutNotificationDefaultMessage" 
            id="bookingBeforeCheckoutNotificationDefaultMessage" 
            :label="getLocalizedText(labels.bookingBeforeCheckoutNotificationDefaultMessage)" 
            :placeholder="getLocalizedText(labels.bookingBeforeCheckoutNotificationDefaultMessage_placeholder)" 
            :languageCodes="availableLanguageCodes" 
            labelAsColumn 
          />
          <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="agencyConfigurationForm.isStoreCartActivated" 
            id="updateIsActivated" 
            :label="getLocalizedText(labels.isStoreCartActivated)" 
            :labelAsColumn="true"
          />
          <Select 
            :selectOptions="{
              options: availableLanguageCodes
            }"
            v-bind:value.sync="agencyConfigurationForm.notificationLanguageCode" 
            id="updateNotificationLanguageCode" 
            :label="getLocalizedText(labels.notificationLanguageCode)" 
            :placeholder="getLocalizedText(labels.notificationLanguageCode_placeholder)"
            labelAsColumn 
          >
          </Select>

          <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="button" @click="onAgencyUpdate">{{ getLocalizedText(labels.updateButton) }}</button>
          <button class="btn btn-primary ladda-button publish" data-style="zoom-in" type="button" @click="onAgencyPublish">{{ getLocalizedText(labels.publishButton) }}</button>
        </div>
      </div>

      <h3>Production</h3>
      <div class="row">
        <div v-if="productionAgencyConfig" class="col-lg-6">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ getLocalizedText(labels.bookingNotificationEMail) }}</label>
            <div class="col-lg-9">
              <span class="form-control-plaintext">{{ productionAgencyConfig.bookingNotificationEMail ? productionAgencyConfig.bookingNotificationEMail.email : '' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ getLocalizedText(labels.bookingValidationNotificationDefaultMessage) }}</label>
            <div class="col-lg-9">
              <span class="form-control-plaintext" v-html="getLocalizedText(productionAgencyConfig.bookingValidationNotificationDefaultMessage)"></span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ getLocalizedText(labels.bookingCancelationNotificationDefaultReason) }}</label>
            <div class="col-lg-9">
              <span class="form-control-plaintext" v-html="getLocalizedText(productionAgencyConfig.bookingCancelationNotificationDefaultReason)"></span>
            </div>
          </div>
          <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="productionAgencyConfig.isStoreCartActivated" 
            :label="getLocalizedText(labels.isStoreCartActivated)" 
            :labelAsColumn="true"
            readonly
          />
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ getLocalizedText(labels.notificationLanguageCode) }}</label>
            <div class="col-lg-9">
              <span class="form-control-plaintext"> {{ productionAgencyConfig.notificationLanguageCode }}</span>
            </div>
          </div>
        </div>
        <div v-else class="col-lg-6">
          {{ getLocalizedText(labels.noAgencyConfigurationInProduction) }}          
        </div>
      </div>
    
    </div>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useAgencyAdmin } from '../../../composables/useAgencyAdmin';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import { LocalizedContentOptions } from '@root/src/client/components/panels/input/content/interfaces';

export default defineComponent({
  props: {
  },
  components: {
    TextField,
    LocalizedText,
    LocalizedContent,
    Select,
    SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { isListLoading: isAgencyListLoading, agencyConfigurationForm, selectedAgency, updateAgencyConfiguration, publishAgencyConfiguration, updateAgencyConfigurationFormForUpdate } = useAgencyAdmin(props, context);

    const productionAgencyConfig = computed(() => {
      if (selectedAgency.value && selectedAgency.value.productionAgency && selectedAgency.value.productionAgency.configuration) {
        return selectedAgency.value.productionAgency.configuration;
      }
      return {};
    })

    updateAgencyConfigurationFormForUpdate(selectedAgency.value.agency);

    const labels = {
      "title" : {
        "fr" : "Configuration",
        "en" : "Settings"
      },
      "bookingNotificationEMail" : {
        "fr" : "EMail",
        "en" : "EMail"
      },
      "bookingNotificationEMail_placeholder" : {
        "fr" : "Adresse email de notification",
        "en" : "Notification email address"
      },
      "bookingValidationNotificationDefaultMessage" : {
        "fr" : "Message par defaut de validation de réservation",
        "en" : "Default booking validation message"
      },
      "bookingValidationNotificationDefaultMessage_placeholder" : {
        "fr" : "Message par defaut de validation de réservation",
        "en" : "Default booking validation message"
      },
      "bookingCancelationNotificationDefaultReason" : {
        "fr" : "Raison par défault d'annulation de réservation",
        "en" : "Default booking cancelation reason"
      },
      "bookingCancelationNotificationDefaultReason_placeholder" : {
        "fr" : "Raison par défault d'annulation de réservation",
        "en" : "Default booking cancelation reason"
      },
      "bookingBeforeCheckoutNotificationDefaultMessage": {
        "fr" : "Notification avant sortie",
        "en" : "Notification before checkout"
      },
      "bookingBeforeCheckoutNotificationDefaultMessage_placeholder": {
        "fr" : "Notification avant sortie",
        "en" : "Notification before checkout"
      },
      "isStoreCartActivated" : {
        "fr" : "Stockage des paniers",
        "en" : "Cart storage"
      },
      "notificationLanguageCode" : {
        "fr" : "Langue de notification",
        "en" : "Notification language"
      },
      "notificationLanguageCode_placeholder" : {
        "fr" : "Sélectionner une langue",
        "en" : "Select a language"
      },
      "updateButton" : {
        "fr" : "Sauvegarder",
        "en" : "Save"
      },
      "publishButton" : {
        "fr" : "Publier",
        "en" : "Publish"
      },
      "noAgencyConfigurationInProduction" : {
        "fr" : "Aucune configuration en production",
        "en" : "No configuration in production"
      }
    }

    const listLoading = computed(() => {
      return isAgencyListLoading.value;
    })

    const availableLanguageCodes = computed(() => {
      return ["fr", "en"];
    })

    const onAgencyUpdate = () => {
      return updateAgencyConfiguration();
    }

    const onAgencyPublish = () => {
      return publishAgencyConfiguration();
    }

    

    return {
      listLoading,
      availableLanguageCodes,
      labels,
      productionAgencyConfig,
      agencyConfigurationForm,
      onAgencyUpdate,
      onAgencyPublish
    }
  }
})
</script>